body {
  font-family: 'Montserrat', sans-serif;
}

.references-box p {
  margin-bottom: 32px;
}

.references-box p:last-child {
  margin-bottom: 0;
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.modal--opened {
  position: fixed;
  left: 0;
  right: 0;
}

input[type=radio].square-radio {
  position: relative;
  padding: 0.6em;
  appearance: none;
  outline: 0.1em solid #111;
  background: #EEE;
}

input[type=radio][disabled].square-radio {
  background: #555;
}

input[type=radio].square-radio:checked::before {
  content: "\2713";
  font-size: 1.5em;
  color: green;
  position: absolute;
  left: 0;
  top: -8px;
}

.list {
  margin: 10px 0;
}

div.list table {
  width: 100%;
}

div.list td, div.list th {
  text-align: center;
  padding: 3px;
  border: #555555 1px solid;
}

div.list th {
  font-size: 0.9rem;
}

div.list td:nth-child(2) {
  font-size: 0.9rem;
}

div.list td:nth-child(3) {
  width: 50%;
}

div.list img {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  div.list td, div.list th {
    padding: 10px;
  }

  div.list th {
    font-size: 1rem;
  }

  div.list td:nth-child(1) {
    font-size: 1rem;
  }

  div.list td:nth-child(2) {
    width: 150px;
  }

  div.list td:nth-child(3) {
    font-size: 1rem;
  }
}

.text-stack {
    display: flex;
    flex-direction: column;
}

.text-stack > p:not(:first-child) {
    margin-top: 22px; /* This sets the spacing between elements */
}

.text-stack > h2 {
    margin-top: 44px; /* This sets the spacing between elements */
}
